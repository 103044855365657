<template>
	<ConfirmationPagesLayout>
		<template #content>
			<div class="cassie-vertical-md">
				<FilterBar
					:search-query.sync="confirmationPageSearchQuery"
					search-query-label="Confirmation Page Name"
					:selected-brand-id.sync="selectedAdminPortalBrandId"
					@persistSearchQuery="changeConfirmationPageSearchQuery"
					@persistSelectedBrandId="changeAdminPortalBrandFilter"
				>
					<template #action>
						<PrimaryActionButton
							v-if="!readOnlyPermissions"
							@click="onCreateClick"
						>
							<v-icon
								left
								dark
							>
								mdi-plus
							</v-icon>
							Create
						</PrimaryActionButton>
					</template>
				</FilterBar>
				<SectionCard>
					<template #title>
						Confirmation Pages
					</template>
					<template #body>
						<DataTable
							:headers="tableHeaders"
							:items="filteredConfirmationPages"
							sort-by="confirmationPageId"
							:sort-desc="true"
							@click:row="onEditConfirmationPage"
						>
							<template #item.action="{ item }">
								<IconButton
									:tooltip-text="readOnlyPermissions ? 'View Confirmation Page' : 'Edit Confirmation Page'"
									@click="onEditConfirmationPage(item)"
								>
									{{ readOnlyPermissions ? 'mdi-eye' : 'mdi-pencil' }}
								</IconButton>
								<IconButton
									v-if="!readOnlyPermissions"
									tooltip-text="Delete Confirmation Page"
									@click.stop.prevent="onRemoveConfirmationPage(item)"
								>
									mdi-trash-can
								</IconButton>
							</template>
						</DataTable>
					</template>
				</SectionCard>
				<ConfirmDeleteModal
					v-if="confirmationPageToBeRemoved && !readOnlyPermissions"
					:entity-name="confirmationPageToBeRemoved.confirmationPageName"
					entity-type="Confirmation Page"
					@close="confirmationPageToBeRemoved = null"
					@delete="deleteConfirmationPage"
				/>
			</div>
		</template>
	</ConfirmationPagesLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import ConfirmationPagesLayout from './confirmation-pages-layout.vue'
import FilterBar from '../../../../../shared/components/filter-bar.vue'
import ConfirmDeleteModal from '../../../../../shared/components/confirm-delete-modal.vue'
import DataTable from '../../../../../shared/components/data-table.vue'
import SectionCard from '../../../../../shared/components/section-card.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import { deleteConfirmationPage, getConfirmationPages } from '../../../../../shared/utils/api/confirmation-page.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import { MANAGE_CONFIRMATION_PAGE } from '../../../router/route-names.js'
import { useLabels } from '../../../../../shared/state/admin-portal-navigation.js'
import { changeAdminPortalBrandFilter, selectedAdminPortalBrandId } from '../../../../../shared/state/brands.js'
import { changeConfirmationPageSearchQuery, confirmationPageSearchQuery } from '../../../../../shared/state/confirmation-pages.js'
import { CONFIRMATION_PAGES_MODULE_FULL_PERMISSIONS } from '../../../../../shared/permissions/admin-portal-permissions.js'
export default {
	components: { PrimaryActionButton, SectionCard, DataTable, ConfirmDeleteModal, FilterBar, ConfirmationPagesLayout, IconButton },
	setup () {
		return {
			changeAdminPortalBrandFilter,
			selectedAdminPortalBrandId,
			changeConfirmationPageSearchQuery,
			confirmationPageSearchQuery
		}
	},
	data () {
		return {
			assetsLinks: {},
			confirmationPageToBeRemoved: null,
			confirmationPages: [],
			tableHeaders: [
				{ value: 'confirmationPageId', text: 'ID', width: '5%' },
				{ value: 'brandName', text: useLabels('Brand'), width: '30%' },
				{ value: 'confirmationPageName', text: 'Confirmation Page Name', width: '30%' },
				{ value: 'createdDate', text: 'Created Date', width: '20%' },
				{ value: 'action', text: 'Action', width: '10%', sortable: false }
			]
		}
	},
	computed: {
		...mapGetters({
			checkPermission: 'auth/productAreaPermission'
		}),
		readOnlyPermissions () {
			return !this.checkPermission(CONFIRMATION_PAGES_MODULE_FULL_PERMISSIONS)
		},
		filteredConfirmationPages () {
			return this.confirmationPages.filter(({ confirmationPageName, brandId }) => {
				let check = true
				if (confirmationPageSearchQuery.value) check = confirmationPageName.toLowerCase().includes(confirmationPageSearchQuery.value.toLowerCase())
				if (selectedAdminPortalBrandId.value !== null) check = check && selectedAdminPortalBrandId.value === brandId
				return check
			})
		}
	},
	created () {
		this.loadConfirmationPages()
	},
	methods: {
		async loadConfirmationPages () {
			const { confirmationPages, assetsLinks } = await getConfirmationPages()
			this.confirmationPages = confirmationPages
			this.assetsLinks = assetsLinks
		},
		async deleteConfirmationPage () {
			await deleteConfirmationPage(this.confirmationPageToBeRemoved.confirmationPageId)
			this.confirmationPageToBeRemoved = null
			showSnackbar('This confirmation page has been removed')
			await this.loadConfirmationPages()
		},
		onCreateClick () {
			this.$router.push({
				name: MANAGE_CONFIRMATION_PAGE,
				params: {
					confirmationPageToEdit: null,
					assetsLinks: this.assetsLinks,
					readOnly: this.readOnlyPermissions
				}
			})
		},
		onEditConfirmationPage (confirmationPageToEdit) {
			this.$router.push({
				name: MANAGE_CONFIRMATION_PAGE,
				params: {
					confirmationPageToEdit,
					assetsLinks: this.assetsLinks,
					readOnly: this.readOnlyPermissions
				}
			})
		},
		onRemoveConfirmationPage (item) {
			this.confirmationPageToBeRemoved = item
		}
	}
}
</script>
